<template>
  <section-item>
    <img class="img-content" :src="require(`@/assets/product/${productName}.png`)" width="100%" height="100%">
  </section-item>
</template>

<script>
export default {
  name: "case-detail",
  data() {
    return {
      productName:'',
    }
  },
  mounted() {
    console.log(this.$route)
    this.productName = this.$route.params.name
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .container{
    width: 100%;
  }

</style>